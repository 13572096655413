#info-bar {
  width: 100vw;
  background-color: var(--electricBlue);
  position: relative;
  overflow: hidden;
  transition: height 1s ease-out;
}
#info-bar.admin-bar {
  background-color: var(--adminColor);
  display: flex;
  gap: 15px;
  justify-content: center;
  align-items: center;
}
#info-bar.admin-bar .message{
  width: fit-content;
  color: black;
  position: inherit;
  display: inline-block;
  font-weight: 400;
  top: inherit;
}
#info-bar.admin-bar .logoff-btn{
  background: none;
  border: none;
  font-size: 15px;
  text-decoration: underline;
  cursor: pointer;
}

#info-bar .message {
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: space-around;
  font-weight: 200;
  top: 5px;
  z-index: -1;
}
#info-bar .message.transition {
  z-index: 0;
  transition: left 10s linear;
}

#info-bar .message:nth-child(1) {
  left: 0;
}

#info-bar .message:nth-child(2) {
  left: 100%;
}

#info-bar .message:nth-child(3) {
  left: -100%;
}