@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
:root {
  --electricBlue: #2400ff;
  --mainFont: "Inter";
  --adminColor: #E5B018;
  --hoverTransition: all 0.3s linear;
}
body {
  background-color: #000;
  font-family: var(--mainFont), sans-serif;
  color: #fff;
  overflow: hidden;
}
ul {
  list-style: none;
}
a {
  text-decoration: none;
  color: inherit;
}
