#contact-section {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
#contact-section .contact-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 15px;
}
#contact-section  .contact-titles {
    display: flex;
    justify-content: space-between;
}
#contact-section .contact-content {
    display: flex;
    gap: 5vw;
}
#contact-section .contact-content > * {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
#contact-section .action-title {
    display: flex;
    justify-content: space-between;
}
#contact-section .action-title .back-btn{
    background-color: transparent;
    border: none;
    color: white;
    font-size: 35px;
    cursor: pointer;
}
#contact-section .contact-actions h2{
    text-align: right;
    margin-left: auto;
}
#contact-section .action-choices {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 15px;
}

#contact-section .employer-form {
    display: grid;
    gap: 20px;
    grid-template-areas:
            "comp comp nom nom"
            "mail mail phone phone"
            "contr contr contr contr"
            "desc desc desc desc"
            "x x x send";
}
#contact-section .employer-form > *:nth-child(1){
    grid-area: comp;
}

#contact-section .employer-form > *:nth-child(2){
    grid-area: nom;
}
#contact-section .employer-form > *:nth-child(3){
    grid-area: mail;
}
#contact-section .employer-form > *:nth-child(4){
    grid-area: phone;
}
#contact-section .employer-form > *:nth-child(5){
    grid-area: contr;
}
#contact-section .employer-form > *:nth-child(6){
    grid-area: desc;
}
#contact-section .employer-form > *:nth-child(7){
    grid-area: send;
}

#contact-section .client-form {
    display: grid;
    gap: 20px;
    grid-template-areas:
            "comp comp nom nom"
            "mail mail phone phone"
            "desc desc desc desc"
            "quest quest quest quest"
            "date date price price"
            "x x x send";
}
#contact-section .client-form > *:nth-child(1){
    grid-area: comp;
}

#contact-section .client-form > *:nth-child(2){
    grid-area: nom;
}
#contact-section .client-form > *:nth-child(3){
    grid-area: mail;
}
#contact-section .client-form > *:nth-child(4){
    grid-area: phone;
}
#contact-section .client-form > *:nth-child(5){
    grid-area: desc;
}
#contact-section .client-form .questions{
    grid-area: quest;
    position: relative;
}
#contact-section .client-form .questions hr{
    position: absolute;
    width: 100%;
    top: 50%;
    left: 0;
    z-index: -1;
}
#contact-section .client-form .questions h2{
    background-color: black;
    width: fit-content;
    padding: 0 10px;
}
#contact-section .client-form > *:nth-child(7){
    grid-area: date;
}
#contact-section .client-form > *:nth-child(8){
    grid-area: price;
}
#contact-section .client-form > *:nth-child(9){
    grid-area: send;
}

#contact-section .default-form {
    display: grid;
    gap: 20px;
    grid-template-areas:
            "nom nom phone phone"
            "mail mail mail mail"
            "desc desc desc desc"
            "x x x send";
}
#contact-section .default-form > *:nth-child(1){
    grid-area: nom;
}

#contact-section .default-form > *:nth-child(2){
    grid-area: mail;
}
#contact-section .default-form > *:nth-child(3){
    grid-area: phone;
}
#contact-section .default-form > *:nth-child(4){
    grid-area: desc;
}
#contact-section .default-form > *:nth-child(5){
    grid-area: send;
}
#contact-section .mail-feedback {
    margin-top: 15px;
    text-align: center;
    animation: formAnim 0.5s;
}
#contact-section .mail-feedback .feedback-icon-ok{
    color: #A2FF86;
    margin-left: 5px;
}
#contact-section .mail-feedback .feedback-icon-err{
    color: #F24C3D;
    margin-left: 5px;
}
#contact-section .form-animation{
    animation: formAnim 0.5s;
}
@keyframes formAnim {
    0% {
        opacity: 0;
        transform: translateX(50%);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@media screen and (max-width: 850px) {
    #contact-section {
        height: auto;
    }
    #contact-section .contact-content {
        gap: 5vw;
        flex-direction: column;
    }
    #contact-section .contact-container {
        justify-content: start;
    }
}

@media screen and (max-width: 500px) {
    .skill-description {
        font-size: 15px;
    }

    #contact-section .contact-content > * {
        flex: auto;
        display: block;
        overflow-x: hidden;
    }

    #contact-section .default-form,
    #contact-section .employer-form,
    #contact-section .client-form {
        display: flex;
        flex-direction: column;
    }
}