.multiselect-display {
    font-size: 20px;
    background-color: transparent;
    border: solid 1px white;
    border-radius: 50px;
    color: white;
    padding: 10px 10px 9px 20px;
    width: 100%;
    position: relative;
    cursor: pointer;
}

.multiselect-display:before {
    content: 'l';
    color: transparent;
}

.multiselect-label {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(30px, 45%);
    background-color: black;
    padding: 0 5px;
    color: white;
    transition: all 0.5s ease-in-out;
    text-transform: lowercase;
    z-index: -1;
    height: -moz-fit-content;
}

.multiselect-label:first-letter {
    text-transform: uppercase;
}

.multiselect-label.active {
    z-index: 0;
    font-size: 15px;
    transform: translate(30px, -55%);
}

.multiselect-options-wrapper {
    max-height: 0px;
    overflow: scroll;
    transition: max-height 0.3s linear;
}

.multiselect-options-wrapper::-webkit-scrollbar {
    display: none;
}
.multiselect-options-wrapper.open {
    max-height: 100px;
}

.multiselect-options {
    display: flex;
    flex-direction: column;
}

.multiselect-option {
    padding: 5px 10px;
    cursor: pointer;
    transition: all 0.3s linear;
}

.multiselect-option:hover {
    background-color: var(--adminColor);
    color: black;
}

.multiselect-input {
    opacity: 0;
    position: absolute;
    width: 0;
    height: 0;
}

.multiselect-option.checked {
    background-color: var(--adminColor);
    color: black;
}
.multiselect-selected{
    padding: 0.2rem;
    color: var(--adminColor);
    margin-right: 5px;
}