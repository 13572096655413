.skill-section {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.skill-content {
  flex: auto;
  display: flex;
  gap: 5vw;
}
.skill-content > div {
  flex: 1;
}
.skill-section .logo-wrapper > div {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-items: center;
  align-items: center;
  grid-gap: 25px;
}
.skill-section .logo-container .logo-wrapper {
  padding: 50px;
  border: 1px solid white;
  border-radius: 15px;
  overflow: scroll;
  flex: auto;
  align-self: stretch;
  max-height: 60vh
}

.skill-section .logo-container .logo-wrapper::-webkit-scrollbar {
  display: none;
}

.skill-section .description-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding-top: 50px;
}

.skill-section .description-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  text-align: right;
  overflow: hidden;
}

.skill-section .description-wrapper > div {
  transform: translateX(0px);
  opacity: 0;
}

.skill-section .description-wrapper .skill-title {
  display: flex;
  justify-content: right;
  align-items: center;
  gap: 0.5vw;
}
.skill-section .description-wrapper .skill-project {
  display: flex;
  flex-direction: column;
  gap: 0.5vw;
}
.skill-section .description-wrapper .skill-title .svgClass {
  width: 50px;
}
.skill-section .description-wrapper .admin-actions{
  display: flex;
  gap: 5px;
  justify-content: right;
}

@media screen and (max-width: 850px) {
  .skill-section {
    height: auto;
  }
  .skill-content {
    gap: 5vw;
    flex-direction: column;
    max-height: 80vh;
    overflow: scroll;
  }

  .skill-section .description-container {
    padding: 0;
    padding-bottom: 10px;
  }
}

@media screen and (max-width: 500px) {
  .skill-section .logo-container .logo-wrapper {
    padding: 20px;
    overflow: scroll;
    display: flex;
    max-height: 60vh
  }
  .skill-section .logo-wrapper > div {
    display: flex;
    gap: 15px;
    height: fit-content;
    flex-wrap: nowrap;
  }

  .skill-content > div {
    flex: auto;
  }
}