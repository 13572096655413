.datepicker-input {
    font-size: 20px;
    background-color: transparent;
    border: solid 1px white;
    border-radius: 50px;
    color: white;
    padding: 10px 10px 9px 20px;
    width: 100%;
    transition: color 1s;
}
.datepicker-input:focus{
    color: white !important;
}

input[type="date" i] {
    font-family: var(--mainFont), sans-serif;
    overflow: hidden;
}

.input-container .icon {
    position: absolute;
    top: 3px;
    right: 0;
    font-size: 25px;
    transform: translate(-20px, 30%);
}

input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
}