.link-btn {
    display: flex;
    align-items: center;
    gap: 5px;
    font-weight: 200;
    cursor: pointer;
}

.link-btn .link-icon {
    font-size: 20px;
}

.link-btn .link-text {
    font-weight: 200;
    font-size: 15px;
    max-width: 0;
    overflow: hidden;
    transition: all .5s linear;
}

.link-btn:hover .link-text {
    max-width: 200px;
}
.link-svg{
    width: 20px;
}