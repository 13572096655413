#navbar {
  display: flex;
  justify-content: space-between;
  align-items: start;
}
#navbar .nav-logo {
  cursor: pointer;
}
#navbar .nav-logo h2 {
  font-size: 11px;
  font-weight: 400;
}
#navbar .nav-logo img {
  width: 150px;
}
#navbar .options > div {
  position: relative;
  display: flex;
  gap: 30px;
  font-size: 18px;
  font-weight: 300;
}
#navbar .options {
  display: flex;
}
#navbar .options .slash {
  height: 110%;
  width: 2px;
  background-color: white;
  transform: rotate(15deg);
}

#navbar .mobile-options .selected {
  display: flex;
  position: relative;
  font-size: 20px;
  text-transform: uppercase;
}
#navbar .mobile-options .selected::before {
  content: '';
  position: absolute;
  height: 100%;
  width: 2px;
  background-color: white;
  transform: rotate(15deg);
  left: -15px;
}

#navbar .mobile-options .selection{
  position: fixed;
  border: none;
  background-color: rgba(0,0,0,0.1);
  backdrop-filter: blur(5px);
  z-index: -4;
  height: 100vh;
  width: 100vw;
  right: 0;
  top: 0;
  opacity: 0;
  transition: all 1s linear;

}
#navbar .mobile-options .selection.open{
  z-index: 44;
  opacity: 1;
}
#navbar .mobile-options .selection .options{
  display: flex;
  flex-direction: column;
  gap: 25px;
  width: fit-content;
  height: 100%;
  padding: 100px 20px;
  margin-left: auto;
  background-color: black;
  transform: translateX(100%);
  transition: all 0.5s linear;
}

#navbar .mobile-options .selection.open .options{
  transform: translateX(0);
}