.project-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.project-links{
  display: flex;
  gap: 10px;
}
.project-video {
  position: relative;
  border-radius: 20px;
  overflow: hidden;
  max-height: 60vh;
}

.project-video .text {
  width: 45%;
  text-align: right;
  padding: 20px 10px;
  margin-left: auto;

}
.video-content {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: linear-gradient(to left, var(--electricBlue) 20%, transparent);
  z-index: 3;
  opacity: 1;
  transition: opacity .5s linear;
  overflow: scroll;
  display: flex;
  align-items: center;
}

.video-content::-webkit-scrollbar {
  display: none;
}

.video-content.isPaused {
  animation: fade 1s;
  opacity: 0;
}
.video-display{
  position: relative;
}
#video-btn {
  position: absolute;
  z-index: 4;
  bottom: 15px;
  left: 15px;
  font-size: 50px;
  background: none;
  border: none;
  opacity: 0;
  transition: all .5s;
}
#video-btn.show{
  opacity: 1;
}
#video-btn:hover,
#video-btn:active {
  color: var(--electricBlue);
}

@media screen and (max-width: 850px){
  .project-container {
    height: auto;
  }
  .video-content {
    display: block;
  }
}

@media screen and (max-width: 500px){
  #video-btn {
    font-size: 35px;
    opacity: 1;
  }
  .project-video {
    overflow: auto;
    border-radius: inherit;
  }
  .project-video .text {
    width: 100%;
    text-align: left;
    padding: 20px 10px;
  }
}