.workList-el {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid white;
    padding: 20px 0;
}

.workList-title {
    font-size: 40px;
    font-weight: 300;
}

.workList-data {
    font-size: 15px;
    font-weight: 200;
    display: flex;
    flex-direction: column;
    align-items: end;
    gap: 10px;
}

.workList-data .logo {
    display: flex;
    gap: 5px;
}

.workList-data .admin-actions {
    position: absolute;
    display: flex;
    gap: 5px;
    left: 0;
    bottom: 0;
}

.workList-data .logo .svgClass {
    width: 20px;
}

@media screen and (max-width: 500px) {
    .workList-el {
        flex-direction: column;
        align-items: start;
        padding: 10px 0;
    }

    .workList-data {
        font-size: 12px;
        flex-direction: row;
        align-items: end;
        align-self: end;
    }

    .workList-title {
        font-size: 25px;
        font-weight: 300;
    }

    .workList-data .logo .svgClass {
        width: 15px;
    }
}