.notfound-container {
    height: 100%;
    width: 100%;
    display: flex;
    gap: 5vw;
    align-items: end;
  }
  .notfound-container .presentation {
    flex: 2;
    font-size: clamp(30px, 3.5vw, 65px);
    text-transform: uppercase;
  }
  .notfound-container .objectives {
    flex: 1;
    font-size: clamp(12px, 1.5vw, 16px);
  }
  .back-to-main-btn{
    display: block;
    background: none;
    border: none;
    color: white;
    text-decoration: underline;
    margin-left: auto;
    cursor: pointer;
  }
  @media screen and (max-width: 850px){
    .notfound-container {
      align-self: end;
    }
    .notfound-container .presentation,
    .notfound-container .objectives {
      flex: auto;
    }
  }
  
  @media screen and (max-width: 500px){
    .notfound-container {
      flex-direction: column;
      justify-content: end
    }
    .notfound-container .presentation,
    .notfound-container .objectives {
      flex: none;
    }
  }