.myVideo {
    width: 100%;
    height: 100%;
    max-height: 60vh;
    position: relative;
    z-index: 2;
}

.backgroundVideo{
    position: absolute;
    width: 100%;
    z-index: 0;
}

.video-container{
    height: 100%;
    width: 100%;
}
.video-container::before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: rgba(0,0,0,0.1);
    backdrop-filter: blur(15px);
}

@media screen and (max-width: 850px){

}

@media screen and (max-width: 500px){
    .video-container::before{
        display: none;
    }
    .myVideo {
        height: auto;
    }

    .video-container{
        height: auto;
        width: 100%;
    }
}