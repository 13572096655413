.work-container {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.project-list {
    cursor: pointer;
    font-size: 20px;
    max-height: 58vh;
    overflow-x: scroll;
}

.project-list::-webkit-scrollbar {
    display: none;
}

.project-list .project {
    padding: 20px 0;
    border-bottom: 1px solid white;
}

.project-overview {
    position: absolute;
    border-radius: 5px;
    width: 30%;
    max-height: 30vh;
    transition: opacity 0.3s linear;
    overflow: hidden;
}

.work-title-wrapper {
    display: flex;
    gap: 15px;
    align-items: end;
}
@media screen and (max-width: 850px){
    .work-container {
        height: auto;
        flex: auto;
    }
}