#select-input {
    font-size: 20px;
    background-color: transparent;
    border: solid 1px white;
    border-radius: 50px;
    color: white;
    padding: 10px 10px 9px 20px;
    width: 100%;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
    outline: none;
}
#select-input option {
    background-color: black;
    border: none;
}
#select-input option:hover {
    background-color: red;
}