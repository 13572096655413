.footer-container {
  border-bottom: 1px solid white;
  padding: 10px 0;
  margin-top: auto;
}

@media screen and (max-width: 500px){
  .footer-container {
    border: none;
  }
  .page-title-container{
    align-items: end;
  }
  .hide{
    display: none;
  }
}