.App {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.main-container {
  width: 100%;
  flex: auto;
  display: grid;
  grid-template-rows: max-content auto max-content;
  padding: 30px;
  max-width: 1600px;
  margin: auto;
}
.main-container .content {
  padding-top: 25px;
}
.page-title-container{
  display: flex;
  align-items: end;
  gap: 5px;
}
.page-title {
  font-size: clamp(30px, 3vw, 45px);
  text-transform: uppercase;
  font-weight: 300;
  line-height: 0.8; /*A revoir*/
}
.page-data {
  display: flex;
  gap: 5vw;
  align-items: end;
}
.page-data > * {
  flex: 1;
}
.main-title{
  font-weight: 300;
  font-size: 35px;
  margin-bottom: 10px;
}
.input-container {
  position: relative;
  font-family: sans-serif;
  width: 100%;
  font-size: 20px;
}
@media screen and (max-width: 850px){
  .App {
    overflow: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    height: 100vh;
  }
  .main-container .content {
    flex: auto;
    display: flex;
  }
  .main-container {
    height: 100%;
    width: 100%;
    flex: auto;
    padding: 15px;
  }
  .page-title {
    line-height: 1;
  }
  .page-data > * {
    flex: auto;
  }
}
@media screen and (max-width: 500px){
  .page-data {
    flex-direction: column;
    align-items: start;
    padding: 10px 5px;
    border-left: 1px solid white;
  }
  .main-container .content {
    max-height: 70vh;
    overflow: scroll;
  }
}