#admin-edition{

}
.admin-modal form{
    display: flex;
    flex-direction: column;
    gap: 25px;
    justify-content: space-between;
}
.admin-modal {
    left: 50%;
    top: 0;
    translate: -50% 50%;
    background-color: transparent;
}
.modal-content {
    position: relative;
    color: white;
    padding: 30px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    background-color: black;
    border: 1px solid var(--adminColor);
    z-index: 2;
}
.modal-content textarea{
    height: fit-content;
}

.admin-modal::backdrop {
    background-color: #000000;
    opacity: 0.3;
}
.edition-work-btn{
    padding: 20px 0;
    border-bottom: 1px solid white;
    font-size: 40px;
    font-weight: 300;
    cursor: pointer;
}
.edition-skill-btn {
    font-size: 100px;
    cursor: pointer;
}
.modal-title{
    font-weight: 200;
    font-size: 45px;
    text-align: center;
    padding: 20px;
}
.close-modal-btn{
    background-color: transparent;
    border: none;
    color: white;
    font-size: 30px;
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
    z-index: 2;
}
.delete-text-warning{
    text-align: center;
}
.admin-icon {
    transition: var(--hoverTransition);
}
.admin-icon:hover{
    color: var(--electricBlue);
}
.admin-icon.warning-color:hover{
    color: var(--adminColor);
}