.logo-icon {
  width: 100px;
  transition: var(--hoverTransition);
  background-repeat: no-repeat;
  background-size: contain;
}
.cls-1 {
  fill: black;
  transition: var(--hoverTransition);
}
.cls-2 {
  fill: white;
  transition: var(--hoverTransition);
}

.logo-icon:hover .cls-1,
.logo-icon.active .cls-1 
{
  fill: white;
}
.logo-icon:hover .cls-2,
.logo-icon.active .cls-2 
{
  fill: var(--electricBlue);
}

@media screen and (max-width: 500px) {
  .logo-icon {
    width: 60px;
  }
}