.home-container {
  height: 100%;
  width: 100%;
  display: flex;
  gap: 5vw;
  align-items: end;
}
.home-container .presentation {
  flex: 2;
  font-size: clamp(30px, 3.5vw, 65px);
  text-transform: uppercase;
}
.home-container .objectives {
  flex: 1;
  font-size: clamp(12px, 1.5vw, 16px);
}
@media screen and (max-width: 850px){
  .home-container {
    align-self: end;
  }
  .home-container .presentation,
  .home-container .objectives {
    flex: auto;
  }
}

@media screen and (max-width: 500px){
  .home-container {
    flex-direction: column;
    justify-content: end
  }
  .home-container .presentation,
  .home-container .objectives {
    flex: none;
  }
}