.form__label {
  font-size: 20px;
  position: absolute;
  height: fit-content;
  top: 0;
  left: 0;
  transform: translate(30px, 45%);
  background-color: black;
  padding: 0 5px;
  color: white;
  transition: all 0.5s ease-in-out;
  text-transform: lowercase;
  z-index: -1;
}

.form__label:first-letter {
  text-transform: uppercase;
}

.form__input {
  font-size: 20px;
  background-color: transparent;
  border: solid 1px white;
  border-radius: 50px;
  color: white;
  padding: 10px 10px 9px 20px;
  width: 100%;
}

textarea.form__input::-webkit-scrollbar {
  display: none;
}
textarea.form__input {
  font-family: var(--mainFont);
  border-radius: 10px;
  resize: none;
}

.input-container:focus-within label.form__label,
label.form__label.active {
  z-index: 0;
  font-size: 15px;
  transform: translate(30px, -55%);
}
.form__input:-webkit-autofill,
.form__input:-webkit-autofill:hover,
.form__input:-webkit-autofill:focus{
  -webkit-text-fill-color: white;
  -webkit-box-shadow: 0 0 0 1000px #000 inset;
  transition: background-color 5000s ease-in-out 0s;
}

@media screen and (max-width: 500px) {
  .form__label {
    font-size: 15px;
    transform: translate(20px, 70%);
  }
  .input-container:focus-within label.form__label,
  label.form__label.active {
    font-size: 13px;
    transform: translate(20px, -55%);
  }
}