.currency-input {
    display: flex;
}
.currency-input .form__input{
    border-radius: 50px 0 0 50px;
}

.currency-input .form__input{
    border-radius: 50px 0 0 50px;
    border-right: none;

}

.currency-input .form__input{
    border-radius: 50px 0 0 50px;
}

.currency-input .currency-select{
    border-radius: 0 50px 50px 0;
    font-size: 20px;
    background-color: transparent;
    border: solid 1px white;
    color: white;
    padding: 10px;
    padding-bottom: 9px;
    width: 100px;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
    outline: none;
}
.currency-input .currency-select option {
    background-color: black;
    border: none;
}
.currency-input .currency-select option:hover {
    background-color: red;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
}

@media screen and (max-width: 500px) {
    .currency-input .currency-select{
        font-size: 15px;
        padding: 10px;
        padding-left: 20px;
    }
}