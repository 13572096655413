.btn-main {
  position: relative;
  width: 100%;
  padding: 10px 20px;
  font-size: 20px;
  background-color: transparent;
  border: solid 1px white;
  border-radius: 50px;
  color: white;
  cursor: pointer;
  --color: #000;
  --hover: #fff;
  transition: 0.5s;
  text-transform: uppercase;
}
.btn-main:hover,
.btn-main:active {
  color: var(--color);
  box-shadow: inset 0 0 0 2em var(--hover);
}
.btn-main.clicked:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  border: solid 1px white;
  border-radius: 50px;
  animation: scale 0.5s;
  animation-name: clickedAnim;
}

@keyframes clickedAnim {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.05, 1.3);
  }
  100% {
    transform: scale(1, 1);
  }
}

@media screen and (max-width: 500px) {
  .btn-main {
    padding: 10px;
    font-size: 15px;
  }
}